<template>
  <div class="edp-backend">
    <edp-backend-user />

    <section class="edp-backend-middle">
      <edp-backend-menu></edp-backend-menu>
      <section class="edp-backend-content">
        <div class="edp-backend-content-out">
          <mainTitle>
            <span class="anyRating-main-title-titleWord">Evaluation / Template Detail</span>
          </mainTitle>
          <div class="addQuestionnaires">
            <div class="app-container questionConent">
              <div class="content">
                <el-row :gutter="20" class="row">
                  <el-col :span="24" class="searchTitle">
                    <i class="el-icon-back pot" @click="backTemplateList"/>
                    <span>{{ $t("questionnaireTemplate.templateDetail") }}</span>
                  </el-col>
                </el-row>
                <el-form
                  :disabled="pageType == 1 || pageType == 3"
                  :model="question"
                >
                  <el-row :gutter="20" class="row" type="flex" align="middle">
                    <el-col :span="5" class="contentTitle">
                      <span class="redMark">*</span>
                      <span>{{ $t("questionnaire.quesTitle") }}</span>
                    </el-col>
                    <el-col :span="15">
                      <el-form-item prop="title" style="display: inline-block">
                        <el-input
                          autosize
                          type="textarea"
                          v-model="question.title"
                          maxlength="100"
                          :placeholder="$t('edpBackendCommon.placeholder')"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="4" class="promptMsg">
                      <span
                        >{{ $t("questionnaire.most") }} 100
                        {{ $t("questionnaire.words") }}</span
                      >
                    </el-col>
                  </el-row>
                  <el-row :gutter="20" class="row" type="flex" align="middle">
                    <el-col :span="5" class="contentTitle">
                      <span class="redMark">*</span>
                      <span>{{ $t("questionnaire.welcomeMsg") }}</span>
                    </el-col>
                    <el-col :span="15">
                      <el-form-item
                        prop="greetWord"
                        style="display: inline-block"
                      >
                        <el-input
                          autosize
                          type="textarea"
                          v-model="question.greetWord"
                          maxlength="500"
                          :placeholder="$t('edpBackendCommon.placeholder')"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="4" class="promptMsg">
                      <span
                        >{{ $t("questionnaire.most") }} 500
                        {{ $t("questionnaire.words") }}</span
                      >
                    </el-col>
                  </el-row>
                  <el-row :gutter="20" class="row" type="flex" align="middle">
                    <el-col :span="5" class="contentTitle">
                      <span>{{ $t("questionnaire.conclusion") }}</span>
                    </el-col>
                    <el-col :span="15">
                      <el-form-item
                        prop="conclusion"
                        style="display: inline-block"
                      >
                        <el-input
                          autosize
                          type="textarea"
                          v-model="question.conclusion"
                          maxlength="500"
                          :placeholder="$t('edpBackendCommon.placeholder')"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="4" class="promptMsg">
                      <span
                        >{{ $t("questionnaire.most") }} 500
                        {{ $t("questionnaire.words") }}</span
                      >
                    </el-col>
                  </el-row>
                </el-form>
                <el-row :gutter="20" class="row">
                  <el-col :span="24">
                    <div class="dividingCla" />
                  </el-col>
                </el-row>
                <!-- <div v-for="(item, index) in question.questions" :key="index">{{item.label}}</div> -->
                <el-form
                  ref="questionsRef"
                  :disabled="pageType == 1 || pageType == 3"
                  :model="question"
                >
                  <draggable
                    :disabled="pageType != 2"
                    :list="question.questions"
                    group="itxst"
                    @end="endDra"
                    handle=".draggable-element"
                  >
                    <el-row
                      v-for="(item, index) in question.questions"
                      :gutter="20"
                      :key="index"
                      class="row"
                    >
                      <el-col :span="24">
                        <div
                          v-if="item.id == '1' || item.id == '2'"
                          class="questionListCla"
                        >
                          <div class="questionListTitle">
                            <div class="titleInput">
                              <span class="redMark">*</span>
                              <span class="titleIndex">{{ index + 1 }}.</span>

                              <el-form-item
                                :prop="`questions.${index}.name`"
                                :rules="nameRule"
                              >
                                <el-input
                                  v-model="item.name"
                                  :placeholder="
                                    item.id == '1'
                                      ? $t('questionnaire.SingleChoice')
                                      : $t('questionnaire.MultipleChoice')
                                  "
                                />
                              </el-form-item>
                              <div v-if="pageType == 2" class="img-wrap">
                                <el-upload
                                  :show-file-list="false"
                                  :before-upload="
                                    (file) => {
                                      imgUpload(file, index);
                                    }
                                  "
                                  class="avatar-uploader"
                                  accept="image/*"
                                  action
                                >
                                  <img
                                    class="upload-icon"
                                    src="../../../assets/pic-icon.png"
                                    alt
                                  />
                                </el-upload>

                                <!-- <el-upload
                                  :show-file-list="false"
                                  :before-upload="
                                    (file) => {
                                      videoUpload(file, index, 2);
                                    }
                                  "
                                  class="avatar-uploader avatar-uploader-mic"
                                  accept=".wav, .mp3, .webm"
                                  action
                                >
                                  <i class="el-icon-mic" />
                                </el-upload>

                                <el-upload
                                  :show-file-list="false"
                                  :before-upload="
                                    (file) => {
                                      videoUpload(file, index, 3);
                                    }
                                  "
                                  class="avatar-uploader"
                                  accept=".mp4, .webm, .ogg"
                                  action
                                >
                                  <img
                                    class="upload-icon"
                                    src="../../../assets/video-icon.png"
                                    alt
                                  />
                                </el-upload> -->
                              </div>
                            </div>
                            <!-- 这个地方 -->
                            <div
                              v-if="
                                (item.mediaType == 1 ||
                                  item.mediaType == 2 ||
                                  item.mediaType == 3) &&
                                item.showUrl
                              "
                              class="file-content"
                            >
                              <video
                                v-if="item.mediaType == 3 && item.showUrl"
                                :src="item.showUrl"
                                style="
                                  width: 100px;
                                  height: 100px;
                                  margin-left: 100px;
                                "
                                @click="downLoad(item)"
                              />
                              <audio
                                v-if="item.mediaType == 2 && item.showUrl"
                                :src="item.showUrl"
                                style="width: 100px; margin-left: 100px"
                                @click="downLoad(item)"
                                controls
                              />
                              <img
                                v-if="item.mediaType == 1 && item.showUrl"
                                :src="item.showUrl"
                                style="
                                  width: 100px;
                                  height: 100px;
                                  margin-left: 100px;
                                "
                                @click="downLoad(item)"
                              />
                              <i
                                v-if="pageType == 2"
                                class="el-icon-circle-close"
                                @click="deleteFile(index)"
                              />
                            </div>

                            <div class="titleIcon" v-if="pageType == 2">
                              <div class="draggable-element">
                                <img
                                  class="upload-icon"
                                  src="../../../assets/drag.png"
                                  alt
                                />
                              </div>
                              <div>
                                <i
                                  class="el-icon-delete"
                                  @click="deleteQuestionList(index)"
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            v-for="(item1, index1) in item.options"
                            :key="index1"
                            class="questionContent"
                          >
                            <template
                              v-if="item.options && item.options.length > 0"
                            >
                              <div v-if="item.id == '1'" class="circleCla">
                                <template v-if="item1.isChoose === 1">
                                  <el-radio
                                    disabled
                                    v-model="item1.value"
                                    :label="item1.value"
                                  >
                                    <span
                                      v-if="item1.isOther == '1'"
                                      class="other-element"
                                      >{{ $t("questionnaire.other") }}</span
                                    >
                                    <span v-else />
                                  </el-radio>
                                </template>
                                <template v-else>
                                  <el-radio
                                    disabled
                                    v-model="item1.answer"
                                    :label="item1.value"
                                  >
                                    <span
                                      v-if="item1.isOther == '1'"
                                      class="other-element"
                                      >{{ $t("questionnaire.other") }}</span
                                    >
                                    <span v-else />
                                  </el-radio>
                                </template>
                              </div>
                              <div v-else class="squareCla">
                                <el-checkbox
                                  disabled
                                  v-model="item1.isChoose"
                                  :label="item1.value"
                                >
                                  <span
                                    v-if="item1.isOther == '1'"
                                    class="other-element"
                                    >{{ $t("questionnaire.other") }}</span
                                  >
                                  <span v-else />
                                </el-checkbox>
                              </div>
                              <el-form-item
                                :prop="`questions.${index}.options.${index1}.content`"
                                :rules="item1.isOther != '1' ? answerRule : []"
                                :class="
                                  item1.isOther == '1' ? 'other-input' : ''
                                "
                                class="answer-item"
                              >
                                <template v-if="item1.isOther && pageType == 1">
                                  <el-input
                                    v-model="item1.answer"
                                    :placeholder="$t('questionnaire.youAnswer')"
                                    class="inputOptionCla"
                                  />
                                </template>
                                <template v-else>
                                  <el-input
                                    v-model="item1.content"
                                    :placeholder="$t('questionnaire.youAnswer')"
                                    class="inputOptionCla"
                                  />
                                </template>
                              </el-form-item>
                              <i
                                v-if="pageType == 2 && index1 > 1"
                                class="el-icon-remove"
                                @click="
                                  deleteSelectContent(item.options, index1)
                                "
                              />
                            </template>
                          </div>
                          <div class="questionContent">
                            <div v-if="pageType == 2" class="questionBtnRow">
                              <span @click="addSelectContent(item, '0')">
                                <i class="el-icon-circle-plus" />{{
                                  $t("questionnaire.addOptions")
                                }}
                              </span>

                              <span
                                v-if="handleIsOther(item.options).length > 0"
                                @click="deleteSelectOtherContent(item.options)"
                              >
                                <i
                                  class="el-icon-remove"
                                  style="display: inline-block"
                                />{{ $t("questionnaire.delOtherOptions") }}
                              </span>
                              <span
                                v-else-if="
                                  !handleIsOther(item.options) ||
                                  handleIsOther(item.options).length == 0
                                "
                                @click="addSelectContent(item, '1')"
                              >
                                <i class="el-icon-circle-plus" />{{
                                  $t("questionnaire.addOtherOptions")
                                }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="item.id == '3' || item.id == '4'"
                          class="questionListCla"
                        >
                          <div class="questionListTitle">
                            <div class="titleInput">
                              <span class="redMark">*</span>
                              <span class="titleIndex">{{ index + 1 }}.</span>
                              <el-form-item
                                :prop="`questions.${index}.name`"
                                :rules="nameRule"
                              >
                                <el-input
                                  v-model="item.name"
                                  :placeholder="
                                    item.id == '3'
                                      ? $t('questionnaire.ImageSingle')
                                      : $t('questionnaire.ImageMultiple')
                                  "
                                />
                              </el-form-item>
                              <div v-if="pageType == 2" class="img-wrap">
                                <el-upload
                                  :show-file-list="false"
                                  :before-upload="
                                    (file) => {
                                      imgUpload(file, index);
                                    }
                                  "
                                  class="avatar-uploader"
                                  accept="image/*"
                                  action
                                >
                                  <img
                                    class="upload-icon"
                                    src="../../../assets/pic-icon.png"
                                    alt
                                  />
                                </el-upload>
                                <!-- <el-upload
                                  :show-file-list="false"
                                  :before-upload="
                                    (file) => {
                                      videoUpload(file, index, 2);
                                    }
                                  "
                                  class="avatar-uploader avatar-uploader-mic"
                                  accept=".wav, .mp3, .webm"
                                  action
                                >
                                  <i class="el-icon-mic" />
                                </el-upload>
                                <el-upload
                                  :show-file-list="false"
                                  :before-upload="
                                    (file) => {
                                      videoUpload(file, index, 3);
                                    }
                                  "
                                  class="avatar-uploader"
                                  accept=".mp4, .webm, .ogg"
                                  action
                                >
                                  <img
                                    class="upload-icon"
                                    src="../../../assets/video-icon.png"
                                    alt
                                  />
                                </el-upload> -->
                              </div>
                            </div>
                            <!-- 这个地方 -->
                            <div
                              v-if="
                                (item.mediaType == 1 ||
                                  item.mediaType == 3 ||
                                  item.mediaType == 2) &&
                                item.showUrl
                              "
                              class="file-content"
                            >
                              <video
                                v-if="item.mediaType == 3 && item.showUrl"
                                :src="item.showUrl"
                                style="
                                  width: 100px;
                                  height: 100px;
                                  margin-left: 100px;
                                "
                                @click="downLoad(item)"
                              />
                              <audio
                                v-if="item.mediaType == 2 && item.showUrl"
                                :src="item.showUrl"
                                style="width: 100px; margin-left: 100px"
                                @click="downLoad(item)"
                                controls
                              />
                              <img
                                v-if="item.mediaType == 1 && item.showUrl"
                                :src="item.showUrl"
                                style="
                                  width: 100px;
                                  height: 100px;
                                  margin-left: 100px;
                                "
                                @click="downLoad(item)"
                              />
                              <i
                                v-if="pageType == 2"
                                class="el-icon-circle-close"
                                @click="deleteFile(index)"
                              />
                            </div>
                            <div class="titleIcon" v-if="pageType == 2">
                              <div class="draggable-element">
                                <img
                                  class="upload-icon"
                                  src="../../../assets/drag.png"
                                  alt
                                />
                              </div>
                              <div>
                                <i
                                  class="el-icon-delete"
                                  @click="deleteQuestionList(index)"
                                />
                              </div>
                            </div>
                            <!-- <div v-if="pageType == 2" class="titleIcon">
                        <i class="el-icon-delete" @click="deleteQuestionList(index)" />
                      </div>-->
                          </div>
                          <div class="questionContent">
                            <div class="imgQuestionCla">
                              <el-form-item
                                :prop="`questions.${index}.fileList`"
                                :rules="imgRule"
                              >
                                <el-upload
                                  :file-list="
                                    item.fileList ? item.fileList : []
                                  "
                                  :on-remove="
                                    (file, fileList) => {
                                      return handleRemove1(file, item);
                                    }
                                  "
                                  :http-request="
                                    (file) => {
                                      return optionUploadImg(file, item);
                                    }
                                  "
                                  action
                                  list-type="picture-card"
                                  accept=".png, .jpg, .jpeg.JPG, .svg"
                                >
                                  <i
                                    v-if="pageType == 2"
                                    class="el-icon-plus"
                                  />
                                </el-upload>
                              </el-form-item>
                              <div>
                                <div
                                  v-for="(item1, index1) in item.options"
                                  v-if="item.id == '3'"
                                  :key="index1"
                                  class="imgCircleDiv"
                                >
                                  <div class="imgCircleCla">
                                    <template v-if="item1.isChoose === 1">
                                      <el-radio
                                        disabled
                                        v-model="item1.value"
                                        :label="item1.value"
                                      >
                                        <span />
                                      </el-radio>
                                    </template>
                                    <template v-else>
                                      <el-radio
                                        disabled
                                        v-model="item1.answer"
                                        :label="item1.value"
                                      >
                                        <span />
                                      </el-radio>
                                    </template>
                                  </div>
                                </div>
                                <div
                                  v-for="(item1, index1) in item.options"
                                  v-if="item.id == '4'"
                                  :key="index1"
                                  class="imgCircleDiv"
                                >
                                  <div class="imgSquareCla">
                                    <el-checkbox
                                      disabled
                                      v-model="
                                        item1.isChoose == 1 ? true : false
                                      "
                                      :label="item1.value"
                                    >
                                      <span />
                                    </el-checkbox>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-if="item.id == '5'" class="questionListCla">
                          <div class="questionListTitle">
                            <div class="titleInput">
                              <span class="redMark">*</span>
                              <span class="titleIndex">{{ index + 1 }}.</span>
                              <el-form-item
                                :prop="`questions.${index}.name`"
                                :rules="nameRule"
                                class="sel-input"
                              >
                                <i class="el-icon-caret-bottom" />
                                <el-input
                                  v-model="item.name"
                                  :placeholder="
                                    $t('edpBackendCommon.placeholder')
                                  "
                                />
                              </el-form-item>
                              <div v-if="pageType == 2" class="img-wrap">
                                <el-upload
                                  :show-file-list="false"
                                  :before-upload="
                                    (file) => {
                                      imgUpload(file, index);
                                    }
                                  "
                                  class="avatar-uploader"
                                  accept="image/*"
                                  action
                                >
                                  <img
                                    class="upload-icon"
                                    src="../../../assets/pic-icon.png"
                                    alt
                                  />
                                </el-upload>
                                <!-- <el-upload
                                  :show-file-list="false"
                                  :before-upload="
                                    (file) => {
                                      videoUpload(file, index, 2);
                                    }
                                  "
                                  class="avatar-uploader avatar-uploader-mic"
                                  accept=".wav, .mp3, .webm"
                                  action
                                >
                                  <i class="el-icon-mic" />
                                </el-upload>
                                <el-upload
                                  :show-file-list="false"
                                  :before-upload="
                                    (file) => {
                                      videoUpload(file, index, 3);
                                    }
                                  "
                                  class="avatar-uploader"
                                  accept=".mp4, .webm, .ogg"
                                  action
                                >
                                  <img
                                    class="upload-icon"
                                    src="../../../assets/video-icon.png"
                                    alt
                                  />
                                </el-upload> -->
                              </div>
                            </div>
                            <!-- 这个地方 -->
                            <div
                              v-if="
                                (item.mediaType == 1 ||
                                  item.mediaType == 3 ||
                                  item.mediaType == 2) &&
                                item.showUrl
                              "
                              class="file-content"
                            >
                              <video
                                v-if="item.mediaType == 3 && item.mediaUrl"
                                :src="item.showUrl"
                                style="
                                  width: 100px;
                                  height: 100px;
                                  margin-left: 100px;
                                "
                                @click="downLoad(item)"
                              />
                              <audio
                                v-if="item.mediaType == 2 && item.showUrl"
                                :src="item.showUrl"
                                style="width: 100px; margin-left: 100px"
                                @click="downLoad(item)"
                                controls
                              />
                              <img
                                v-if="item.mediaType == 1 && item.showUrl"
                                :src="item.showUrl"
                                style="
                                  width: 100px;
                                  height: 100px;
                                  margin-left: 100px;
                                "
                                @click="downLoad(item)"
                              />
                              <i
                                v-if="pageType == 2"
                                class="el-icon-circle-close"
                                @click="deleteFile(index)"
                              />
                            </div>
                            <div class="titleIcon" v-if="pageType == 2">
                              <div class="draggable-element">
                                <img
                                  class="upload-icon"
                                  src="../../../assets/drag.png"
                                  alt
                                />
                              </div>
                              <div>
                                <i
                                  class="el-icon-delete"
                                  @click="deleteQuestionList(index)"
                                />
                              </div>
                            </div>
                            <!-- <div v-if="pageType == 2" class="titleIcon">
                        <i class="el-icon-delete" @click="deleteQuestionList(index)" />
                      </div>-->
                          </div>
                          <div
                            v-for="(item1, index1) in item.options"
                            v-if="item.options && item.options.length > 0"
                            :key="index1"
                            class="questionContent selectQuestionContent"
                          >
                            <el-form-item
                              :prop="`questions.${index}.options.${index1}.content`"
                              :rules="answerRule"
                            >
                              <el-input
                                v-if="pageType == 2"
                                v-model="item1.content"
                                :placeholder="`${$t('questionnaire.option')} ${
                                  index1 + 1
                                }`"
                                class="inputOptionCla"
                              />
                              <el-input
                                v-if="pageType == 3"
                                v-model="item1.content"
                                :placeholder="`${$t('questionnaire.option')} ${
                                  index1 + 1
                                }`"
                                class="inputOptionCla"
                              />
                              <el-input
                                v-if="item1.isChoose == 1 && pageType == 1"
                                v-model="item1.content"
                                :placeholder="`${$t('questionnaire.option')} ${
                                  index1 + 1
                                }`"
                                class="inputOptionCla"
                              />
                            </el-form-item>
                            <i
                              v-if="pageType == 2 && index1 > 1"
                              class="el-icon-remove"
                              @click="deleteSelectContent(item.options, index1)"
                            />
                          </div>
                          <div class="questionContent">
                            <div v-if="pageType == 2" class="questionBtnRow">
                              <span @click="addSelectContent(item)">
                                <i class="el-icon-circle-plus" />{{
                                  $t("questionnaire.addOptions")
                                }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div v-if="item.id == '6'" class="questionListCla">
                          <div class="questionListTitle">
                            <div class="titleInput">
                              <span class="redMark">*</span>
                              <span class="titleIndex">{{ index + 1 }}.</span>
                              <el-form-item
                                :prop="`questions.${index}.name`"
                                :rules="nameRule"
                              >
                                <el-input
                                  v-model="item.name"
                                  :placeholder="$t('questionnaire.Cascading')"
                                />
                              </el-form-item>
                              <div v-if="pageType == 2" class="img-wrap">
                                <el-upload
                                  :show-file-list="false"
                                  :before-upload="
                                    (file) => {
                                      imgUpload(file, index);
                                    }
                                  "
                                  class="avatar-uploader"
                                  accept="image/*"
                                  action
                                >
                                  <img
                                    class="upload-icon"
                                    src="../../../assets/pic-icon.png"
                                    alt
                                  />
                                </el-upload>
                                <!-- <el-upload
                                  :show-file-list="false"
                                  :before-upload="
                                    (file) => {
                                      videoUpload(file, index, 2);
                                    }
                                  "
                                  class="avatar-uploader avatar-uploader-mic"
                                  accept=".wav, .mp3, .webm"
                                  action
                                >
                                  <i class="el-icon-mic" />
                                </el-upload>
                                <el-upload
                                  :show-file-list="false"
                                  :before-upload="
                                    (file) => {
                                      videoUpload(file, index, 3);
                                    }
                                  "
                                  class="avatar-uploader"
                                  accept=".mp4, .webm, .ogg"
                                  action
                                >
                                  <img
                                    class="upload-icon"
                                    src="../../../assets/video-icon.png"
                                    alt
                                  />
                                </el-upload> -->
                              </div>
                            </div>
                            <!-- 这个地方 -->
                            <div
                              v-if="
                                (item.mediaType == 1 ||
                                  item.mediaType == 3 ||
                                  item.mediaType == 2) &&
                                item.showUrl
                              "
                              class="file-content"
                            >
                              <video
                                v-if="item.mediaType == 3 && item.showUrl"
                                :src="item.showUrl"
                                style="
                                  width: 100px;
                                  height: 100px;
                                  margin-left: 100px;
                                "
                                @click="downLoad(item)"
                              />
                              <audio
                                v-if="item.mediaType == 2 && item.showUrl"
                                :src="item.showUrl"
                                style="width: 100px; margin-left: 100px"
                                @click="downLoad(item)"
                                controls
                              />
                              <img
                                v-if="item.mediaType == 1 && item.showUrl"
                                :src="item.showUrl"
                                style="
                                  width: 100px;
                                  height: 100px;
                                  margin-left: 100px;
                                "
                                @click="downLoad(item)"
                              />
                              <i
                                v-if="pageType == 2"
                                class="el-icon-circle-close"
                                @click="deleteFile(index)"
                              />
                            </div>
                            <div class="titleIcon" v-if="pageType == 2">
                              <div class="draggable-element">
                                <img
                                  class="upload-icon"
                                  src="../../../assets/drag.png"
                                  alt
                                />
                              </div>
                              <div>
                                <i
                                  class="el-icon-delete"
                                  @click="deleteQuestionList(index)"
                                />
                              </div>
                            </div>
                            <!-- <div v-if="pageType == 2" class="titleIcon">
                        <i class="el-icon-delete" @click="deleteQuestionList(index)" />
                      </div>-->
                          </div>
                          <div class="questionContent selectQuestionContent">
                            <el-form-item
                              v-if="pageType == 2 || pageType == 3"
                              :prop="`questions.${index}.surveyCascadeCode`"
                              :rules="selRule"
                            >
                              <el-select
                                v-model="item.surveyCascadeCode"
                                style="width: 300px"
                                :placeholder="
                                  $t('questionnaire.selectCascadeType')
                                "
                              >
                                <el-option
                                  v-for="(item, index) in cascadeList"
                                  :key="index"
                                  :label="item.name"
                                  :value="item.code"
                                />
                              </el-select>
                            </el-form-item>
                            <el-form-item
                              v-if="pageType == 1"
                              :prop="`questions.${index}.cascadeDetailName`"
                              :rules="selRule"
                            >
                              <el-select
                                v-model="item.options[0].cascadeDetailName"
                                style="width: 300px"
                                placeholder="$t('questionnaire.selectCascadeType')"
                              >
                                <el-option
                                  v-for="(item, index) in cascadeList"
                                  :key="index"
                                  :label="item.name"
                                  :value="item.code"
                                />
                              </el-select>
                            </el-form-item>
                          </div>
                        </div>
                        <div v-if="item.id == '7'" class="questionListCla">
                          <div class="questionListTitle">
                            <div class="titleInput">
                              <span class="redMark">*</span>
                              <span class="titleIndex">{{ index + 1 }}.</span>

                              <el-form-item
                                :prop="`questions.${index}.name`"
                                :rules="nameRule"
                              >
                                <el-input
                                  v-model="item.name"
                                  :placeholder="$t('questionnaire.FreeText')"
                                />
                              </el-form-item>
                              <div v-if="pageType == 2" class="img-wrap">
                                <el-upload
                                  :show-file-list="false"
                                  :before-upload="
                                    (file) => {
                                      imgUpload(file, index);
                                    }
                                  "
                                  class="avatar-uploader"
                                  accept="image/*"
                                  action
                                >
                                  <img
                                    class="upload-icon"
                                    src="../../../assets/pic-icon.png"
                                    alt
                                  />
                                </el-upload>
                                <!-- <el-upload
                                  :show-file-list="false"
                                  :before-upload="
                                    (file) => {
                                      videoUpload(file, index, 2);
                                    }
                                  "
                                  class="avatar-uploader avatar-uploader-mic"
                                  accept=".wav, .mp3, .webm"
                                  action
                                >
                                  <i class="el-icon-mic" />
                                </el-upload>
                                <el-upload
                                  :show-file-list="false"
                                  :before-upload="
                                    (file) => {
                                      videoUpload(file, index, 3);
                                    }
                                  "
                                  class="avatar-uploader"
                                  accept=".mp4, .webm, .ogg"
                                  action
                                >
                                  <img
                                    class="upload-icon"
                                    src="../../../assets/video-icon.png"
                                    alt
                                  />
                                </el-upload> -->
                              </div>
                            </div>
                            <!-- 这个地方 -->
                            <div
                              v-if="
                                (item.mediaType == 1 ||
                                  item.mediaType == 3 ||
                                  item.mediaType == 2) &&
                                item.showUrl
                              "
                              class="file-content"
                            >
                              <video
                                v-if="item.mediaType == 3 && item.showUrl"
                                :src="item.showUrl"
                                style="
                                  width: 100px;
                                  height: 100px;
                                  margin-left: 100px;
                                "
                                @click="downLoad(item)"
                              />
                              <audio
                                v-if="item.mediaType == 2 && item.showUrl"
                                :src="item.showUrl"
                                style="width: 100px; margin-left: 100px"
                                @click="downLoad(item)"
                                controls
                              />
                              <img
                                v-if="item.mediaType == 1 && item.showUrl"
                                :src="item.showUrl"
                                style="
                                  width: 100px;
                                  height: 100px;
                                  margin-left: 100px;
                                "
                                @click="downLoad(item)"
                              />
                              <i
                                v-if="pageType == 2"
                                class="el-icon-circle-close"
                                @click="deleteFile(index)"
                              />
                            </div>
                            <div class="titleIcon" v-if="pageType == 2">
                              <div class="draggable-element">
                                <img
                                  class="upload-icon"
                                  src="../../../assets/drag.png"
                                  alt
                                />
                              </div>
                              <div>
                                <i
                                  class="el-icon-delete"
                                  @click="deleteQuestionList(index)"
                                />
                              </div>
                            </div>
                            <!-- <div v-if="pageType == 2" class="titleIcon">
                        <i class="el-icon-delete" @click="deleteQuestionList(index)" />
                      </div>-->
                          </div>
                          <div
                            v-if="item.options && item.options.length > 0"
                            class="questionContent selectQuestionContent"
                          >
                            <el-form-item
                              :prop="`questions.${index}.options.${0}.answer`"
                              :rules="answerRule"
                              v-if="item.options[0].answer"
                            >
                              <el-input
                                :rows="2"
                                v-model="item.options[0].answer"
                                type="textarea"
                                class="inputOptionCla"
                                :placeholder="$t('questionnaire.youAnswer')"
                              />
                            </el-form-item>
                            <el-form-item
                              v-else
                              :prop="`questions.${index}.options.${0}.content`"
                              :rules="answerRule"
                            >
                              <el-input
                                :rows="2"
                                v-model="item.options[0].content"
                                type="textarea"
                                class="inputOptionCla"
                                :placeholder="$t('questionnaire.youAnswer')"
                              />
                               <!-- yaoli -->
                               <template>
                                <el-radio v-model="item.required" label='1'>{{ $t("questionnaire.textNecessary") }}</el-radio>
                                <el-radio v-model="item.required" label='0'>{{ $t("questionnaire.textUnnecessary") }}</el-radio>
                              </template>
                            </el-form-item>
                          </div>
                        </div>
                        <div v-if="item.id == '8'" class="questionListCla">
                          <div class="questionListTitle">
                            <div class="titleInput">
                              <span class="redMark">*</span>
                              <span class="titleIndex">{{ index + 1 }}.</span>
                              <el-form-item
                                :prop="`questions.${index}.name`"
                                :rules="nameRule"
                              >
                                <el-input
                                  v-model="item.name"
                                  :placeholder="$t('questionnaire.RatingScale')"
                                />
                              </el-form-item>
                              <div v-if="pageType == 2" class="img-wrap">
                                <el-upload
                                  :show-file-list="false"
                                  :before-upload="
                                    (file) => {
                                      imgUpload(file, index);
                                    }
                                  "
                                  class="avatar-uploader"
                                  accept="image/*"
                                  action
                                >
                                  <img
                                    class="upload-icon"
                                    src="../../../assets/pic-icon.png"
                                    alt
                                  />
                                </el-upload>
                                <!-- <el-upload
                                  :show-file-list="false"
                                  :before-upload="
                                    (file) => {
                                      videoUpload(file, index, 2);
                                    }
                                  "
                                  class="avatar-uploader avatar-uploader-mic"
                                  accept=".wav, .mp3, .webm"
                                  action
                                >
                                  <i class="el-icon-mic" />
                                </el-upload>
                                <el-upload
                                  :show-file-list="false"
                                  :before-upload="
                                    (file) => {
                                      videoUpload(file, index, 3);
                                    }
                                  "
                                  class="avatar-uploader"
                                  accept=".mp4, .webm, .ogg"
                                  action
                                >
                                  <img
                                    class="upload-icon"
                                    src="../../../assets/video-icon.png"
                                    alt
                                  />
                                </el-upload> -->
                              </div>
                            </div>
                            <!-- 这个地方 -->
                            <div
                              v-if="
                                (item.mediaType == 1 ||
                                  item.mediaType == 3 ||
                                  item.mediaType == 2) &&
                                item.showUrl
                              "
                              class="file-content"
                            >
                              <video
                                v-if="item.mediaType == 3 && item.showUrl"
                                :src="item.showUrl"
                                style="
                                  width: 100px;
                                  height: 100px;
                                  margin-left: 100px;
                                "
                                @click="downLoad(item)"
                              />
                              <audio
                                v-if="item.mediaType == 2 && item.showUrl"
                                :src="item.showUrl"
                                style="width: 100px; margin-left: 100px"
                                @click="downLoad(item)"
                                controls
                              />
                              <img
                                v-if="item.mediaType == 1 && item.showUrl"
                                :src="item.showUrl"
                                style="
                                  width: 100px;
                                  height: 100px;
                                  margin-left: 100px;
                                "
                                @click="downLoad(item)"
                              />
                              <i
                                v-if="pageType == 2"
                                class="el-icon-circle-close"
                                @click="deleteFile(index)"
                              />
                            </div>
                            <div class="titleIcon" v-if="pageType == 2">
                              <div class="draggable-element">
                                <img
                                  class="upload-icon"
                                  src="../../../assets/drag.png"
                                  alt
                                />
                              </div>
                              <div>
                                <i
                                  class="el-icon-delete"
                                  @click="deleteQuestionList(index)"
                                />
                              </div>
                            </div>
                            <!-- <div v-if="pageType == 2" class="titleIcon">
                        <i class="el-icon-delete" @click="deleteQuestionList(index)" />
                      </div>-->
                          </div>
                          <div
                            v-for="(item1, index1) in item.options"
                            :key="index1"
                            class="questionContent"
                          >
                            <el-rate v-model="item1.answer" disabled/>
                            <!-- <el-form-item
                              :prop="`questions.${index}.options.${index1}.content`"
                              :rules="answerRule"
                              class="rate-item"
                            >
                              <el-input
                                v-model="item1.content"
                                :placeholder="`${$t(
                                  'questionnaire.evalDimension'
                                )} ${index1 + 1}`"
                                class="inputOptionCla"
                              />
                            </el-form-item> -->
                            <i
                              v-if="pageType == 2 && index1 > 1"
                              class="el-icon-remove"
                              @click="deleteSelectContent(item.options, index1)"
                            />
                          </div>
                          <div class="questionContent">
                            <div v-if="pageType == 2 && index1 < 1" class="questionBtnRow">
                              <span @click="addSelectContent(item)">
                                <i class="el-icon-circle-plus" />{{
                                  $t("questionnaire.addOptions")
                                }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div v-if="item.id == '9'" class="questionListCla">
                          <div class="questionListTitle">
                            <div class="titleInput">
                              <span class="redMark">*</span>
                              <span class="titleIndex">{{ index + 1 }}.</span>
                              <el-form-item
                                :prop="`questions.${index}.name`"
                                :rules="labelRule"
                              >
                                <el-input
                                  v-model="item.name"
                                  :placeholder="$t('questionnaire.lable')"
                                />
                              </el-form-item>
                            </div>
                            <div class="titleIcon" v-if="pageType == 2">
                              <div class="draggable-element">
                                <img
                                  class="upload-icon"
                                  src="../../../assets/drag.png"
                                  alt
                                />
                              </div>
                              <div>
                                <i
                                  class="el-icon-delete"
                                  @click="deleteQuestionList(index)"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-if="item.id == '10'" class="questionListCla">
                          <div class="questionListTitle">
                            <div class="titleInput">
                              <span class="redMark">*</span>
                              <span class="titleIndex">{{ index + 1 }}.</span>
                              <el-form-item
                                :prop="`questions.${index}.name`"
                                :rules="nameRule"
                                class="sel-input"
                              >
                                <i class="el-icon-caret-bottom" />
                                <el-input
                                  v-model="item.name"
                                  maxlength="500"
                                  :placeholder="
                                    $t('edpBackendCommon.placeholder')
                                  "
                                />
                              </el-form-item>
                              <div v-if="pageType == 2" class="img-wrap">
                                <el-upload
                                  :show-file-list="false"
                                  :before-upload="
                                    (file) => {
                                      imgUpload(file, index);
                                    }
                                  "
                                  class="avatar-uploader"
                                  accept="jpg,.jpeg,.png,.bmp,webp"
                                  action
                                >
                                  <img
                                    class="upload-icon"
                                    src="../../../assets/pic-icon.png"
                                    alt
                                  />
                                </el-upload>
                                <!-- <el-upload
                                  :show-file-list="false"
                                  :before-upload="
                                    (file) => {
                                      videoUpload(file, index, 2);
                                    }
                                  "
                                  class="avatar-uploader avatar-uploader-mic"
                                  accept=".wav, .mp3, .webm"
                                  action
                                >
                                  <i class="el-icon-mic" />
                                </el-upload>
                                <el-upload
                                  :show-file-list="false"
                                  :before-upload="
                                    (file) => {
                                      videoUpload(file, index, 3);
                                    }
                                  "
                                  class="avatar-uploader"
                                  accept=".mp4, .webm, .ogg"
                                  action
                                >
                                  <img
                                    class="upload-icon"
                                    src="../../../assets/video-icon.png"
                                    alt
                                  />
                                </el-upload> -->
                              </div>
                            </div>
                            <!-- 这个地方 -->
                            <div
                              v-if="
                                (item.mediaType == 1 ||
                                  item.mediaType == 3 ||
                                  item.mediaType == 2) &&
                                item.showUrl
                              "
                              class="file-content"
                            >
                              <video
                                v-if="item.mediaType == 3 && item.mediaUrl"
                                :src="item.showUrl"
                                style="
                                  width: 100px;
                                  height: 100px;
                                  margin-left: 100px;
                                "
                                @click="downLoad(item)"
                              />
                              <audio
                                v-if="item.mediaType == 2 && item.showUrl"
                                :src="item.showUrl"
                                style="width: 100px; margin-left: 100px"
                                @click="downLoad(item)"
                                controls
                              />
                              <img
                                v-if="item.mediaType == 1 && item.showUrl"
                                :src="item.showUrl"
                                style="
                                  width: 100px;
                                  height: 100px;
                                  margin-left: 100px;
                                "
                                @click="downLoad(item)"
                              />
                              <i
                                v-if="pageType == 2"
                                class="el-icon-circle-close"
                                @click="deleteFile(index)"
                              />
                            </div>
                            <div class="titleIcon" v-if="pageType == 2">
                              <div class="draggable-element">
                                <img
                                  class="upload-icon"
                                  src="../../../assets/drag.png"
                                  alt
                                />
                              </div>
                              <div>
                                <i
                                  class="el-icon-delete"
                                  @click="deleteQuestionList(index)"
                                />
                              </div>
                            </div>
                            <!-- <div v-if="pageType == 2" class="titleIcon">
                        <i class="el-icon-delete" @click="deleteQuestionList(index)" />
                      </div>-->
                          </div>
                          <div
                            v-for="(item1, index1) in item.options"
                            :key="index1"
                            class="questionContent"
                          >
                            <div class="circleCla">
                              <template>
                                <span class="other-element">L{{ index1+1 }}</span>
                              </template>
                            </div>
                            <template
                              v-if="item.options && item.options.length > 0"
                            >
                              <el-form-item
                                :prop="`questions.${index}.options.${index1}.content`"
                                class="answer-item"
                              >
                                <template>
                                  <el-input
                                    disabled
                                    v-model="item1.content"
                                    class="inputOptionCla"
                                  />
                                </template>
                              </el-form-item>
                            </template>
                          </div>
                          <div class="questionContent">
                            <div class="questionBtnRow">
                              <!-- <span @click="addSelectContent(item, '0')">
                                <i class="el-icon-tickets" />{{
                                  $t("questionnaire.downloadFile")
                                }}
                              </span> -->

                              <span @click="downLoadUrl('temp')">
                                <i
                                  class="el-icon-download"
                                  style="display: inline-block"
                                />{{ $t("questionnaire.downloadTemplete") }}
                              </span>
                              <el-upload
                                :show-file-list="false"
                                :before-upload="
                                  (file) => {
                                    cascadeUpload(file, index);
                                  }
                                "
                                class="cascad-uploader"
                                accept=".xlsx"
                                action
                              >
                                <span>
                                  <i class="el-icon-upload2" />{{
                                    $t("questionnaire.upload")
                                  }}
                                </span>
                              </el-upload>
                            </div>
                          </div>
                        </div>
                        <div v-if="item.id == '11'" class="questionListCla">
                          <div class="questionListTitle">
                            <div class="titleInput">
                              <span class="redMark">*</span>
                              <span class="titleIndex">{{ index + 1 }}.</span>
                              <el-form-item
                                :prop="`questions.${index}.name`"
                                :rules="nameRule"
                                class="sel-input"
                              >
                                <i class="el-icon-caret-bottom" />
                                <el-input
                                  v-model="item.name"
                                  :placeholder="
                                    $t('edpBackendCommon.placeholder')
                                  "
                                />
                              </el-form-item>
                              <div v-if="pageType == 2" class="img-wrap">
                                <el-upload
                                  :show-file-list="false"
                                  :before-upload="
                                    (file) => {
                                      imgUpload(file, index);
                                    }
                                  "
                                  class="avatar-uploader"
                                  accept="image/*"
                                  action
                                >
                                  <img
                                    class="upload-icon"
                                    src="../../../assets/pic-icon.png"
                                    alt
                                  />
                                </el-upload>
                                <!-- <el-upload
                                  :show-file-list="false"
                                  :before-upload="
                                    (file) => {
                                      videoUpload(file, index, 2);
                                    }
                                  "
                                  class="avatar-uploader avatar-uploader-mic"
                                  accept=".wav, .mp3, .webm"
                                  action
                                >
                                  <i class="el-icon-mic" />
                                </el-upload>
                                <el-upload
                                  :show-file-list="false"
                                  :before-upload="
                                    (file) => {
                                      videoUpload(file, index, 3);
                                    }
                                  "
                                  class="avatar-uploader"
                                  accept=".mp4, .webm, .ogg"
                                  action
                                >
                                  <img
                                    class="upload-icon"
                                    src="../../../assets/video-icon.png"
                                    alt
                                  />
                                </el-upload> -->
                              </div>
                            </div>
                            <!-- 这个地方 -->
                            <div
                              v-if="
                                (item.mediaType == 1 ||
                                  item.mediaType == 3 ||
                                  item.mediaType == 2) &&
                                item.showUrl
                              "
                              class="file-content"
                            >
                              <video
                                v-if="item.mediaType == 3 && item.mediaUrl"
                                :src="item.showUrl"
                                style="
                                  width: 100px;
                                  height: 100px;
                                  margin-left: 100px;
                                "
                                @click="downLoad(item)"
                              />
                              <audio
                                v-if="item.mediaType == 2 && item.showUrl"
                                :src="item.showUrl"
                                style="width: 100px; margin-left: 100px"
                                @click="downLoad(item)"
                                controls
                              />
                              <img
                                v-if="item.mediaType == 1 && item.showUrl"
                                :src="item.showUrl"
                                style="
                                  width: 100px;
                                  height: 100px;
                                  margin-left: 100px;
                                "
                                @click="downLoad(item)"
                              />
                              <i
                                v-if="pageType == 2"
                                class="el-icon-circle-close"
                                @click="deleteFile(index)"
                              />
                            </div>
                            <div class="titleIcon" v-if="pageType == 2">
                              <div class="draggable-element">
                                <img
                                  class="upload-icon"
                                  src="../../../assets/drag.png"
                                  alt
                                />
                              </div>
                              <div>
                                <i
                                  class="el-icon-delete"
                                  @click="deleteQuestionList(index)"
                                />
                              </div>
                            </div>
                            <!-- <div v-if="pageType == 2" class="titleIcon">
                        <i class="el-icon-delete" @click="deleteQuestionList(index)" />
                      </div>-->
                          </div>
                          <div
                            v-for="(item1, index1) in item.options"
                            v-if="item.options && item.options.length > 0"
                            :key="index1"
                            class="questionContent selectQuestionContent"
                          >
                            <el-form-item
                              :prop="`questions.${index}.options.${index1}.content`"
                              :rules="answerRule"
                            >
                              <el-input
                                v-if="pageType == 2"
                                v-model="item1.content"
                                :placeholder="`${$t('questionnaire.option')} ${
                                  index1 + 1
                                }`"
                                class="inputOptionCla"
                              />
                              <el-input
                                v-if="pageType == 3"
                                v-model="item1.content"
                                :placeholder="`${$t('questionnaire.option')} ${
                                  index1 + 1
                                }`"
                                class="inputOptionCla"
                              />
                              <el-input
                                v-if="item1.isChoose == 1 && pageType == 1"
                                v-model="item1.content"
                                :placeholder="`${$t('questionnaire.option')} ${
                                  index1 + 1
                                }`"
                                class="inputOptionCla"
                              />
                            </el-form-item>
                            <i
                              v-if="pageType == 2 && index1 > 1"
                              class="el-icon-remove"
                              @click="deleteSelectContent(item.options, index1)"
                            />
                          </div>
                          <div class="questionContent">
                            <div class="circleCla">
                                  <el-radio
                                    :disabled="question.isAnswer"
                                    v-for="(item1,i) in empSelect"
                                    :key="i"
                                    v-model="item.employeeSelectType"
                                    :label="item1.value"
                                  >
                                    <span
                                      class="other-element"
                                      >{{ item1.name }}</span
                                    >
                                  </el-radio>
                              </div>
                          </div>
                        </div>
                      </el-col>
                    </el-row>
                    <el-row v-if="pageType == 2" :gutter="20" class="row">
                      <el-col :span="24">
                        <div class="toolArea">
                          <i class="el-icon-plus"></i>
                          <span>{{ $t("questionnaire.pleaseDrag") }}</span>
                        </div>
                      </el-col>
                    </el-row>
                  </draggable>
                </el-form>
                <el-row :gutter="20" class="row">
                  <el-col :span="24">
                    <div class="saveBtnRow">
                      <el-button type="transparent" @click="backTemplateList">{{
                        $t("edpBackendCommon.return")
                      }}</el-button>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div class="questionTool">
              <!-- <div class="app-container shadow"> -->
              <div class="content">
                <el-form ref="setupRef" :model="question">
                  <el-row :gutter="20" class="row">
                    <el-col :span="24" class="searchTitle">
                      <i class="el-icon-setting" />
                      <span style="margin-left: 5px">{{
                        $t("questionnaire.quesSetting")
                      }}</span>
                    </el-col>
                  </el-row>
                  <!-- <el-row :gutter="20" class="row" type="flex" align="middle">
                      <el-col :span="6" class="contentTitle rowLeft">{{
                        $t("questionnaire.endTime")
                      }}</el-col>
                      <el-col :span="18" class="rowLeft">
                        <el-form-item
                          :rules="[
                            {
                              required: true,
                              message: `${$t('questionnaire.endTime')}`,
                              trigger: 'change',
                            },
                          ]"
                          prop="endTime"
                        >
                          <el-date-picker
                            :disabled="pageType == 1"
                            v-model="question.endTime"
                            type="datetime"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            :placeholder="$t('questionnaire.endTime')"
                          >
                          </el-date-picker>
                        </el-form-item>
                      </el-col>
                    </el-row> -->
                  <el-row :gutter="20" class="row" type="flex" align="middle">
                    <el-col :span="10" class="contentTitle rowLeft">{{
                      $t("questionnaire.coverImage")
                    }}</el-col>
                    <el-col :span="14" class="rowLeft coverImg">
                      <el-form-item
                        :rules="[
                          {
                            required: true,
                            message: `${$t('questionnaire.coverImage')}`,
                            trigger: 'change',
                          },
                        ]"
                        prop="coverImg"
                      >
                        <UploadImage
                          class="avatar-uploader-publish"
                          :disabled="true"
                          :limit="1"
                          :imgList="[{ name: 'cover-img', url: question.coverImg }]"
                          :preview="true"
                          :previewTitle="$t('questionnaire.coverImage')"
                          v-if="question.coverImg"
                        />
                        <span v-else>--</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20" class="row" type="flex" align="middle">
                    <el-col :span="10" class="contentTitle rowLeft">{{
                      $t("questionnaire.homePic")
                    }}</el-col>
                    <el-col :span="14" class="rowLeft coverImg">
                      <el-form-item
                        prop="homePic"
                      >
                        <UploadImage
                          class="avatar-uploader-publish"
                          :disabled="true"
                          :limit="1"
                          :imgList="[{ name: 'cover-img', url: question.homePageUrl }]"
                          :preview="true"
                          :previewTitle="$t('questionnaire.homePic')"
                          v-if="question.homePageUrl"
                        />
                        <span v-else>--</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20" class="row" type="flex" align="middle">
                    <el-col :span="10" class="contentTitle rowLeft">{{
                      $t("questionnaire.endPic")
                    }}</el-col>
                    <el-col :span="14" class="rowLeft coverImg">
                      <el-form-item
                        prop="coverImg"
                      >
                        <UploadImage
                          class="avatar-uploader-publish"
                          :disabled="true"
                          :limit="1"
                          :imgList="[{ name: 'cover-img', url: question.endPageUrl }]"
                          :preview="true"
                          :previewTitle="$t('questionnaire.endPic')"
                          v-if="question.endPageUrl"
                        />
                        <span v-else>--</span>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20" class="row" type="flex" align="middle">
                    <el-col :span="10" class="contentTitle rowLeft">{{
                      $t("questionnaire.Onepage")
                    }}</el-col>
                    <el-col :span="14" class="rowLeft">
                      <el-switch
                        :disabled="pageType == 1 || pageType == 3"
                        v-model="question.isPageForEach"
                        active-color="#3E886D"
                        active-value="1"
                        inactive-value="0"
                        style="width: 30px;"
                      />
                    </el-col>
                  </el-row>

                  <!-- <el-row :gutter="20" class="row" type="flex" align="middle">
                      <el-col :span="6" class="contentTitle rowLeft">{{
                        $t("questionnaire.PublishingSystem")
                      }}</el-col>
                      <el-col :span="18" class="rowLeft">
                        <el-form-item
                          :rules="[
                            {
                              required: true,
                              message: `${$t(
                                'questionnaire.PublishingSystem'
                              )}`,
                              trigger: 'change',
                            },
                          ]"
                          prop="publishSystems"
                        >
                          <el-select
                            :disabled="pageType == 1"
                            v-model="question.publishSystems"
                            multiple
                            :placeholder="$t('questionnaire.PublishingSystem')"
                          >
                            <el-option
                              v-for="(item, index) in publishSystemList"
                              :key="index"
                              :label="item.content"
                              :value="item.code"
                            />
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-row> -->
                  <!-- <el-row :gutter="20" class="row" type="flex" align="middle">
                <el-col :span="6" class="contentTitle rowLeft">奖品设置</el-col>
                <el-col :span="18" class="rowLeft">
                  <el-radio
                    :disabled="pageType == 1"
                    v-model="question.rewardType"
                    label="1"
                    >积分</el-radio
                  >
                  <el-radio
                    :disabled="pageType == 1"
                    v-model="question.rewardType"
                    label="2"
                    >优惠劵</el-radio
                  >
                </el-col>
              </el-row> -->
                  <!-- <el-row :gutter="20" class type="flex" align="middle">
                <el-col :span="24" class="contentTitle rowLeft">
                  <el-form-item
                    v-if="question.rewardType == '1'"
                    :rules="[
                      {
                        required: true,
                        message: '请输入电力值',
                        trigger: 'blur',
                      },
                      {
                        type: 'number',
                        min: 0,
                        max: 5000,
                        message: '请输入5000以下数字',
                        trigger: 'blur',
                      },
                    ]"
                    prop="score"
                  >
                    <el-input
                      :disabled="pageType == 1"
                      v-model.number="question.score"
                      placeholder="输入电力值数量"
                    />
                  </el-form-item>
                  <el-form-item
                    v-else
                    :rules="[
                      {
                        required: true,
                        message: '请选择优惠券',
                        trigger: 'change',
                      },
                    ]"
                    prop="couponModelCode"
                  >
                    <el-select
                      :disabled="pageType == 1"
                      v-model="question.couponModelCode"
                      style="width: 100%"
                      placeholder="优惠劵"
                    >
                      <el-option
                        v-for="item in scoreList"
                        :key="item.couponModelCode"
                        :label="item.couponModelName"
                        :value="item.couponModelCode"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row> -->
                </el-form>
              </div>
              <!-- </div> -->
              <div class="questionSel" v-if="pageType == 2">
                <div class="content">
                  <el-row :gutter="20">
                    <el-col :span="20" class="searchTitle">
                      <i class="el-icon-set-up" />
                      <span style="margin-left: 5px">{{
                        $t("questionnaire.QuestiontypeSelection")
                      }}</span>
                    </el-col>
                  </el-row>
                  <draggable
                    :disabled="pageType != 2"
                    :list="questionTypeList"
                    :group="groupA"
                    :sort="false"
                    :clone="cloneDefaultField"
                    class="questionTypeDra"
                    @end="endDra"
                  >
                    <transition-group>
                      <div
                        v-for="(item, i) in questionTypeList"
                        :key="item.id && item.label ? item.id : ''"
                        :class="
                          i % 2 == 0
                            ? 'questionTypeCla questionTypeLeft'
                            : 'questionTypeCla questionTypeRight'
                        "
                        @click="addquestion(item.id)"
                        v-show="item.id && item.label"
                      >
                        {{ item.label }}
                      </div>
                    </transition-group>
                  </draggable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  </div>
  <!-- </div> -->
</template>

<script>
import draggable from "vuedraggable";
import quessionnaire from "./quessionnaire";
import UploadImage from "../../../components/upload/entryUpload.vue";
import upload from "../../../common/fileUpload.js";
import _ from "lodash";
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
export default {
  name: "TemplateDetail",
  components: {
    draggable,
    quessionnaire,
    UploadImage,
  },
  data() {
    return {
      groupA: { name: "itxst", pull: "clone" },
      fileImgList: [],
      homeImgList: [],
      endImgList: [],
      value1: null,
      empSelect: [{
        name: this.$t("questionnaire.SingleChoice"),
        value: 1,
      },
    {
        name: this.$t("questionnaire.MultipleChoice"),
        value: 2,
      }  ],
      publishSystemList: [
        { content: "APP", code: "1" },
        { content: "H5", code: "2" },
      ],
      rules: {
        title: [
          {
            required: false,
            message: "请填写问卷标题",
            trigger: "change",
          },
          {
            min: 1,
            max: 100,
            message: "长度不能超过100个字",
            trigger: "change",
          },
        ],
        greetWord: [
          {
            min: 1,
            max: 500,
            message: "长度不能超过500个字",
            trigger: "change",
          },
        ],
      },
      nameRule: [
        {
          required: true,
          message: "请填写问题内容",
          trigger: "blur",
        },
      ],
      answerRule: [
        {
          required: true,
          message: "请填写选项内容",
          trigger: "blur",
        },
      ],
      selRule: [
        {
          required: true,
          message: "请选择级联类型",
          trigger: "blur",
        },
      ],
      labelRule: [
        {
          required: true,
          message: "请填写段落说明",
          trigger: "blur",
        },
      ],
      imgRule: [
        {
          required: true,
          message: "请上传图片",
          trigger: "blur",
        },
      ],
      jlDialogVisible: false,
      selectRadioDialogVisible: false,
      dialogImageUrl: "",
      drag: false,
      value: true,
      radio: "1",
      yhvalue: null,
      radioSelectList: [],
      checkSelectList: [],
      scoreList: [],
      questionTypeList: [
        {
          id: "1",
          label: `${this.$t("questionnaire.SingleChoice")}`,
        },
        {
          id: "2",
          label: `${this.$t("questionnaire.MultipleChoice")}`,
        },
        {
          id: "3",
          label: `${this.$t("questionnaire.ImageSingle")}`,
        },
        {
          id: "4",
          label: `${this.$t("questionnaire.ImageMultiple")}`,
        },
        {
          id: "5",
          label: `${this.$t("questionnaire.Dropdown")}`,
        },
        {
          id: "6",
          label: `${this.$t("questionnaire.Cascading")}`,
        },
        {
          id: "7",
          label: `${this.$t("questionnaire.FreeText")}`,
          options: [
            {
              content: null,
            },
          ],
        },
        {
          id: "8",
          label: `${this.$t("questionnaire.RatingScale")}`,
          options: [
            {
              content: null,
            },
          ],
        },
        {
          id: "9",
          label: `${this.$t("questionnaire.lable")}`,
        },
        {
          id: "10",
          label: `${this.$t("questionnaire.CascadImport")}`,
        },
        {
          id: "11",
          label: `${this.$t("questionnaire.employee")}`,
        },
      ],
      jlList1: [],
      jlList2: [],
      jlList3: [],
      jlList4: [],
      addJlType: "",
      listIndex1: "",
      listIndex2: "",
      listIndex3: "",
      imageUrl: "",
      question: {
        id: null,
        title: null,
        greetWord: null,
        isPageForEach: "1",
        publishSystems: null,
        rewardType: "1",
        score: null,
        questions: [],
        couponModelCode: null,
        coverImg: null,
        pageAddress: null,
        qrUrl: null,
        endTime: null,
        conclusion: null,
        endPageUrl: null,
        homePageUrl: null,
        required: '1'
      },
      id: null,
      cascadeList: [],
      pageType: 3,
      previewShow: false,
     
    };
  },
  computed: {
    actionUrl: () => {
      const basePath =
        process.env.NODE_ENV === "development" ? "/api" : "/member-admin";
      return basePath + "/business/upload";
    },
  },
  watch: {
    questionList(new_list, old_list) {
      console.log("new_list", new_list);
    },
  },
  mounted() {
    this.id = this.$route.query.id;
    if (this.id) {
      this.question.id = this.id;
      this.viewInit(this.id);
    }
    const type = this.$route.query.type;
    if (type) {
      this.pageType = type;
    }
    this.getDatas();
  },
  methods: {
    ...mapActions({
      surveyView: "survey/surveyView",
      surveyCascade: "survey/surveyCascade",
      addSurvey: "survey/addSurvey",
      editQuestion: "survey/editQuestion",
      templateDetail: "questionnaireTemplate/templateDetail"
    }),
    deleteFile(index) {
      this.question.questions[index].mediaType = 0;
      this.question.questions[index].mediaUrl = "";
    },
    downLoad(item) {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", item.mediaUrl, true);
      xhr.responseType = "arraybuffer"; // 返回类型blob
      xhr.onload = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
          const blob = this.response;
          // 转换一个blob链接
          // 注: URL.createObjectURL() 静态方法会创建一个 DOMString(DOMString 是一个UTF-16字符串)，
          // 其中包含一个表示参数中给出的对象的URL。这个URL的生命周期和创建它的窗口中的document绑定
          const downLoadUrl = window.URL.createObjectURL(
            new Blob([blob], {
              type: item.mediaType == 1 ? "image/jpeg" : "video/mp4",
            })
          );
          // 视频的type是video/mp4，图片是image/jpeg
          // 01.创建a标签
          const a = document.createElement("a");
          // 02.给a标签的属性download设定名称
          a.download = item.label;
          // 03.设置下载的文件名
          a.href = downLoadUrl;
          // 04.对a标签做一个隐藏处理
          a.style.display = "none";
          // 05.向文档中添加a标签
          document.body.appendChild(a);
          // 06.启动点击事件
          a.click();
          // 07.下载完毕删除此标签
          a.remove();
        }
      };
      xhr.send();
      // window.url = item.mediaUrl
    },
    getDatas() {
      this.surveyCascade().then((res) => {
        if (res.records) {
          this.cascadeList = res.records;
          console.log(this.cascadeList);
        }
      });
    },
    imgUpload(file, val) {
      if (this.question.questions[val].mediaUrl) {
        this.$message({
          message: "已存在",
          type: "error",
        });
        return;
      }
      upload(file, (res) => {
        let temp = {
          name: res.attachment,
          url: res.host + "/" + res.aliyunAddress,
        };
        // op.onSuccess(temp);
        this.$set(this.question.questions[val], "mediaType", 1);
        this.$set(this.question.questions[val], "mediaUrl", res.aliyunAddress);
        this.$set(this.question.questions[val], "showUrl", temp.url);
      });
    },
    videoUpload(file, val, type) {
      // console.log(file, val);
      if (this.question.questions[val].mediaUrl) {
        this.$message({
          message: "已存在",
          type: "error",
        });
        return;
      }
      const formData = new FormData();
      formData.append("file", file);
      upLoadFile(formData).then((res) => {
        if (res.success === true) {
          // this.form.headUrl = res.result;
          this.$set(this.question.questions[val], "mediaType", type);
          this.$set(this.question.questions[val], "mediaUrl", res.result);
          this.$message({
            message: "上传成功",
            type: "success",
          });
        }
      });
      // console.log("this.question.questions", this.question.questions);
    },
    addquestion(val) {
      if (this.pageType != 2) {
        return false;
      }

      const item = {
        mediaType: 0,
        mediaUrl: "",
        name: "",
        sort: 0,
        surveyCascadeCode: "",
        surveyId: 0,
        type: val,
        id: val,
        employeeSelectType: null
      };
      // console.log("val", val);
      if (val == "7") {
        this.question.questions.push({
          ...item,
          options: [
            {
              content: null,
            },
          ],
        });
      } else {
        this.question.questions.push(item);
      }

      // console.log(this.question.questions);
    },
    cloneDefaultField(e) {
      console.log(e);
      return JSON.parse(JSON.stringify(e));
    },
    viewInit(id) {
      this.templateDetail({
        id: id,
        param: this.$route.query,
      }).then((res) => {
        if (res.success === true) {
          this.question = res.data;
          if (res.data.coverImg) {
            this.fileImgList = [{ name: "111", url: res.data.coverImg }];
          }
          if (res.data.homePageUrl) {
            this.homeImgList = [{ name: "222", url: res.data.homePageUrl }];
          }
          if (res.data.endPageUrl) {
            this.endImgList = [{ name: "333", url: res.data.endPageUrl }];
          }
          if (this.question.rewardType) {
            this.question.rewardType = this.question.rewardType + "";
          }
          if (this.question.isPageForEach) {
            this.question.isPageForEach = this.question.isPageForEach + "";
          }
          if (this.question.questions && this.question.questions.length > 0) {
            const questionList = this.question.questions;
            for (let i = 0; i < questionList.length; i++) {
              const qt = questionList[i];
              this.$set(qt, "id", qt.type);
              if (qt.options && qt.options.length > 0) {
                const fileList = [];
                for (let j = 0; j < qt.options.length; j++) {
                  if (qt.type == "2") {
                    if (qt.options[j].isChoose == "1") {
                      qt.options[j].isChoose = true;
                    }
                  }
                  if (qt.type == "3" || qt.type == "4") {
                    const file = {
                      url: qt.options[j].content,
                    };
                    fileList.push(file);
                    if (file.url == qt.options[j].content) {
                      qt.options[j].fileParam = file;
                    }
                  }
                  this.$set(qt, "fileList", fileList);
                  if (qt.type == "8") {
                    qt.options[j].answer = Number(qt.options[j].answer);
                  }
                }
              }
              // yaoli
              if(qt.type == '7'){
                qt.required = questionList[i].required + '';
              }
              console.log("qt.options", qt);
            }
          }
        }
      });
    },
    coverImgUpload(files) {
      this.fileImgList = files;
      console.log("fileImgList", this.fileImgList);
      if (this.fileImgList.length) {
        this.question.coverImg = files[0].name;
      } else {
        this.question.coverImg = null;
      }
    },
    homeImgUpload(files) {
      this.homeImgList = files;
      console.log("homeImgList", this.homeImgList);
      if (this.homeImgList.length) {
        this.question.homePageUrl = files[0].name;
      } else {
        this.question.homePageUrl = null;
      }
    },
    endImgUpload(files) {
      this.endImgList = files;
      console.log("endImgList", this.endImgList);
      if (this.endImgList.length) {
        this.question.endPageUrl = files[0].name;
      } else {
        this.question.endPageUrl = null;
      }
    },
    questionSave(flag) {
      console.log("this.question", this.question);
      if (flag === 'on-preview' && this.pageType == 3) {
        window.open(`${window.location.origin}/#/backend-questionnaire-preview?id=${this.question.id}&code=${this.question.code}`, '_blank');
        return;
      }
      if (
        !this.question.title &&
        !this.question.greetWord &&
        !this.question.coverImg
      ) {
        this.$message({
          message: this.$t("questionnaire.tipsOne"),
          type: "warning",
        });
        return false;
      }
      if (!this.question.questions.length) {
        this.$message({
          message: this.$t("questionnaire.tipsTwo"),
          type: "warning",
        });
        return false;
      }
      const result = [];
      const p1 = new Promise((resolve, reject) => {
        this.$refs["setupRef"].validate((valid) => {
          if (valid) {
            resolve();
          } else {
            reject();
          }
        });
      });
      result.push(p1);
      const p2 = new Promise((resolve, reject) => {
        this.$refs["questionsRef"].validate((valid) => {
          console.log("valid", valid);
          if (valid) {
            resolve();
          } else {
            reject();
          }
        });
      });
      result.push(p2);
      console.log("result", result);
      Promise.all(result)
        .then(() => {
          if (this.question.questions.length > 0) {
            for (let i = 0; i < this.question.questions.length; i++) {
              const qt = this.question.questions[i];
              this.$set(qt, "type", JSON.parse(JSON.stringify(qt.id)));
              this.$set(qt, "sort", JSON.parse(JSON.stringify(i + 1)));
              if (qt.type == "6") {
                this.$set(
                  qt,
                  "options",
                  JSON.parse(
                    JSON.stringify([
                      {
                        sort: 1,
                      },
                    ])
                  )
                );
              }
              if (qt.type != 9 && qt.type != 10 && qt.type != 11) {
                if (qt.options && qt.options.length > 0) {
                  for (let j = 0; j < qt.options.length; j++) {
                    if (qt.options[j].isChoose) {
                      qt.options[j].isChoose = 1;
                    } else {
                      qt.options[j].isChoose = 0;
                    }
                    if (qt.options[j].isOther && !qt.options[j].content) {
                      this.$message({
                        message: this.$t("questionnaire.otherTips"),
                        type: "warning",
                      });
                      return false;
                    }
                  }
                } else {
                  this.$message({
                    message: this.$t("questionnaire.optionTips"),
                    type: "warning",
                  });
                  return false;
                }
              }
            }
          }
          const params = this.question;
          if (Array.isArray(params.publishSystems)) {
            params.publishSystems = params.publishSystems.join(",");
          }
          console.log("this.id", this.id);
          if (this.id) {
            params.id = this.id;
            this.editQuestion(params).then((res) => {
              if (res.success === true) {
                this.$message({
                  message: this.$t("edpBackendCommon.actionSuccess"),
                  type: "success",
                });
                if (flag === 'on-preview') {
                  const id = res.data.id
                  window.open(`${window.location.origin}/#/backend-questionnaire-preview?id=${this.question.id}&code=${this.question.code}`, '_blank');
                  this.$nextTick(() => {
                    this.viewInit(id);
                  });
                } else {
                  this.backTemplateList();
                }
              } else {
                this.question.publishSystems =
                  this.question.publishSystems.split(",");
              }
            });
          } else {
            this.addSurvey(params).then((res) => {
              if (res.success === true) {
                this.$message({
                  message: this.$t("edpBackendCommon.actionSuccess"),
                  type: "success",
                });
                if (flag === 'on-preview') {
                  const id = res.data.id
                  this.id = id;
                  this.question = res.data;
                  this.pageType = 2;
                  this.$router.replace({path: 'backend-addQuestionnaires', query: { type: 2, id: id }});
                  window.open(`${window.location.origin}/#/backend-questionnaire-preview?id=${id}&code=${this.question.code}`, '_blank');
                  this.$nextTick(() => {
                    this.viewInit(id);
                  });
                } else {
                  this.backTemplateList();
                }
              } else {
                this.question.publishSystems =
                  this.question.publishSystems.split(",");
              }
            });
          }
        })
        .catch(function (res) {
          console.log("校验失败", res);
        });
    },
    backTemplateList() {
      this.$router.push({ path: 'backend-questionnaire-template' });
    },
    jlSelectClick(item, index, addJlType) {
      if (addJlType == "2") {
        this.listIndex1 = index;
        this.jlList2 = item.children;
        this.jlList3 = [];
        this.jlList4 = [];
      } else if (addJlType == "3") {
        this.listIndex2 = index;
        this.jlList3 = item.children;
        this.jlList4 = [];
      } else if (addJlType == "4") {
        this.listIndex3 = index;
        this.jlList4 = item.children;
      }
      this.addJlType = addJlType;
    },
    jlAddList(type) {
      const obj = {
        id: 1,
        value: "",
        children: [],
      };
      if (type == "1") {
        this.jlList1.push(obj);
      } else if (type == "2") {
        this.jlList2.push(obj);
      } else if (type == "3") {
        this.jlList3.push(obj);
      } else if (type == "4") {
        this.jlList4.push(obj);
      }
    },
    handleClose() {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    jlDialogSure() {
      this.jlDialogVisible = false;
    },
    handleRemove1(file, item) {
      for (let i = 0; i < item.options.length; i++) {
        if (file.uid == item.options[i].fileParam.uid) {
          item.options.splice(i, 1);
          break;
        }
      }

      for (let i = 0; i < item.fileList.length; i++) {
        if (file.uid == item.fileList[i].uid) {
          item.fileList.splice(i, 1);
          break;
        }
      }
    },
    optionUploadImg(file, item) {
      console.log(file);
      // const formData = new FormData();
      // formData.append("file", file.file);
      // upLoadFile(formData).then((res) => {
      //   if (res.resultStatus === "SUCCEED") {
      upload(file.file, (res) => {
        console.log(res);
        let temp = {
          name: res.attachment,
          url: res.host + "/" + res.aliyunAddress,
        };
        console.log(temp);
        if (item.options) {
          const fileParam = {
            url: temp.url,
            showUrl: res.aliyunAddress,
          };
          const fileList = item.fileList.push(fileParam);
          item.index = item.index + 1;
          const obj = {
            value: item.index,
            sort: item.options.length + 1,
            isChoose: null,
            content: res.aliyunAddress,
            contentUrl: temp.url,
            isOther: null,
            uid: file.file.uid,
            fileList: fileList,
            fileParam,
          };
          item.options.push(obj);
        } else {
          const fileList = [];
          const fileParam = {
            url: temp.url,
            showUrl: res.aliyunAddress,
          };

          fileList.push(fileParam);
          const arr = [];
          const obj = {
            value: 0,
            sort: 1,
            isChoose: null,
            content: res.aliyunAddress,
            contentUrl: temp.url,
            isOther: null,
            uid: file.file.uid,
            fileParam,
          };
          arr.push(obj);

          this.$set(item, "options", arr);
          this.$set(item, "index", 0);
          this.$set(item, "fileList", fileList);
        }
      });
      //   }
      // });
    },
    deleteSelectContent(item, index) {
      item.splice(index, 1);
    },
    editSelectContent(item) {
      this.jlDialogVisible = true;
    },
    deleteQuestionList(index) {
      // this.resultUrl.splice(index, 1);
      this.question.questions.splice(index, 1);
    },
    addSelectContent(item, type) {
      if (item.options) {
        item.index = item.index + 1;
        const obj = {
          value: item.index,
          sort: item.options.length + 1,
          isChoose: "",
          content: null,
          isOther: type,
        };
        item.options.push(obj);
      } else {
        const arr = [];
        const obj = {
          value: 0,
          sort: 1,
          isChoose: "",
          content: null,
          isOther: type,
        };
        arr.push(obj);
        this.$set(item, "options", arr);
        this.$set(item, "index", 0);
      }
    },
    endDra(e) {
      console.log("---this--", this.question.questions);
    },
    onMove(e, originalEvent) {
      console.log("---e--", e);
      console.log("---originalEvent--", originalEvent);
    },
    add1(e) {
      console.log("---add1--", e);
    },
    handleIsOther(item) {
      if (item && item.length > 0) {
        return item.filter((f) => f.isOther == "1");
      } else {
        return false;
      }
    },
    deleteSelectOtherContent(item) {
      if (item && item.length > 0) {
        item.filter((f, index) => {
          if (f.isOther == "1") {
            item.splice(index, 1);
          }
        });
      }
    },
    onPreview() {
      if (this.question.questions.length > 0) {
        for (let i = 0; i < this.question.questions.length; i++) {
          const qt = this.question.questions[i];
          this.$set(qt, "type", JSON.parse(JSON.stringify(qt.id)));
          this.$set(qt, "sort", JSON.parse(JSON.stringify(i + 1)));
          if (qt.type == "6") {
            this.$set(qt, "options", JSON.parse(JSON.stringify([{ sort: 1 }])));
          }
          if (qt.options && qt.options.length > 0) {
            for (let j = 0; j < qt.options.length; j++) {
              if (qt.options[j].isChoose) {
                qt.options[j].isChoose = 1;
              } else {
                qt.options[j].isChoose = 0;
              }
            }
          } else {
            this.$message({
              message: "请添加选项!",
              type: "warning",
            });
            return false;
          }
        }
      }
      this.previewShow = true;
    },
  },
};
</script>
<style scoped lang="less">
.avatar-uploader-publish {
  /deep/ .el-upload.el-upload--picture-card {
    display: none;
  }
  /deep/ .el-upload-list.el-upload-list--picture-card.is-disabled {
    display: inline-flex;
    .el-upload-list__item.is-success {
      margin: 0;
    }
  }
}
.el-input /deep/ .el-input__inner {
  border: 0;
  background-color: rgb(244, 244, 244);
  font-size: 14px;
}
.saveBtnRow {
  text-align: right;

  button {
    width: 100px;
  }
}

.addQuestionnaires {
  display: flex;
  justify-content: center;
  /*min-height: 1000px;*/
  background: #f3f5fa;
}

.questionConent {
  width: 74%;
  height: 86vh;
  overflow-y: auto;
}

.questionTool {
  width: 25%;
  height: 86vh;
  overflow-y: auto;
  margin: 20px 20px 0 0;
}

.searchTitle {
  text-align: left;
  margin-top: 24px;
  font-size: 16px;
  color: #3E886D;
  letter-spacing: 0.32px;

  i {
    font-size: 22;
    font-weight: 600;
  }

  span {
    margin-left: 6px;
  }
}

.row {
  margin-bottom: 8px;
}

.contentTitle {
  font-size: 14px;
  letter-spacing: 0.28px;
  color: rgb(34, 34, 34);
}

.promptMsg {
  font-size: 12px;
  letter-spacing: 0.24px;
  color: rgb(153, 153, 153);
}

.dividingCla {
  height: 1px;
  background-color: rgb(231, 231, 231);
}

.toolArea {
  height: 148px;
  border: dashed 0.5px rgb(153, 153, 153);
  font-size: 14px;
  color: rgb(110, 110, 110);
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    margin-top: 2px;
    width: 13px;
    height: 13px;
  }

  span {
    margin-left: 10px;
  }
}

.rowLeft {
  text-align: left;
}
.questionSel {
  width: 100%;
  margin-top: 20px;
  /*position: fixed;
  right: -70px;
  bottom: 10px;*/
  .questionTypeDra {
    margin: 10px auto;
    span {
      display: flex;
      flex-wrap: wrap;
    }

    .questionTypeCla {
      -webkit-user-drag: element;
      height: 36px;
      border-radius: 4px;
      background-color: rgb(242, 242, 242);
      border: 0.5px rgb(153, 153, 153);
      color: rgb(51, 51, 51);
      font-size: 14px;
      letter-spacing: 0.28px;
      cursor: pointer;
      width: calc(52% - 30px);
      margin-top: 10px;
      // margin-bottom: 8px;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }

    .questionTypeLeft {
      margin-right: 15px;
    }

    .questionTypeRight {
      margin-left: 15px;
    }
  }
}
.preview-btn {
  margin: 10px 10px 0;
  border-color: #bdac9b;
  background: #bdac9b;
}

.questionListCla {
  -webkit-user-drag: element;
  border: 0.5px dashed rgb(153, 153, 153);
  padding: 24px 24px 0;

  .file-content {
    position: relative;
    .el-icon-circle-close {
      right: -6px;
      position: absolute;
      top: -6px;
    }
  }

  .questionListTitle {
    display: flex;
    align-items: center;
  }

  .titleInput {
    width: 80%;
    display: flex;
    align-items: center;

    .titleIndex {
      margin-right: 8px;
    }

    .sel-input {
      position: relative;
      width: 85%;
      i {
        z-index: 10;
        position: absolute;
        left: 6px;
        top: 7px;
        color: #000;
      }
    }

    .img-wrap {
      display: flex;
      margin-left: 10px;

      .upload-icon {
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-left: 8px;
      }
    }
  }

  .titleIcon {
    text-align: right;
    width: 20%;
    display: flex;
    align-items: baseline;
    justify-content: space-around;
    .draggable-element {
      cursor: pointer;
      img {
        width: 16px;
        height: 16px;
      }
    }
    .el-icon-delete {
      color: red;
      cursor: pointer;
    }
  }

  .questionContent {
    margin: 16px 0px;
    display: flex;
    align-items: center;

    .circleCla {
      font-size: 16px;
      margin-left: 30px;
      // margin-right: 12px;
      .other-element {
        margin-right: 12px;
      }
    }

    .squareCla {
      margin-left: 30px;
      // margin-right: 12px;
      .other-element {
        margin-right: 12px;
      }
    }

    .inputOptionCla {
      width: 100%;
    }
    .answer-item{
        width: 50%;
    }
    .other-input {
      .inputOptionCla {
        width: 25% !important;
      }
    }

    .el-rate {
      margin-left: 30px;
    }

    .el-icon-remove {
      cursor: pointer;
      margin-left: 12px;
      display: none;
    }
  }

  .selectQuestionContent {
    width: 70%;
    margin-left: 30px;
  }

  .questionContent:hover {
    .el-icon-remove {
      display: block;
    }
  }

  .questionBtnRow {
    width: 100%;
    text-align: right;

    span {
      margin-left: 48px;
      cursor: pointer;

      i {
        margin-right: 8px;
      }
    }
  }
}

.redMark {
  color: rgb(214, 45, 10);
  margin-top: 5px;
}

.jlDialogCla {
  display: flex;
  height: 418px;

  .jlOptionCla {
    width: 25%;
    border-right: 1px solid rgb(231, 231, 231);
    border-bottom: 1px solid rgb(231, 231, 231);

    .checkDiv {
      background-color: rgb(242, 242, 242);
    }

    .addJlBtnCla {
      cursor: pointer;
    }

    .jlTitle {
      font-size: 13px;
      letter-spacing: 0.26px;
      color: rgb(51, 51, 51);
    }

    .jlItemDiv {
      padding: 16px 0px;
    }

    .selectInput {
      width: 80%;
      margin: auto;
    }
  }
}
</style>
<style lang="less">
.addQuestionnaires {
  .sel-input {
    .el-input__inner {
      text-indent: 1em;
      background-color: transparent;
    }
  }

  .el-form-item {
    margin-bottom: 0px;
    width: 85%;
  }

  .rate-item {
    width: 60%;
  }

  .el-form-item__content .el-input {
    width: 100%;
  }

  .coverImg {
    img {
      width: 64px;
      height: 64px;
    }

    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
      border-color: #409eff;
    }

    .el-icon-upload {
      font-size: 21px;
      color: #8c939d;
      width: 64px;
      height: 64px;
      line-height: 64px;
      text-align: center;
    }

    .avatar {
      width: 64px;
      height: 64px;
      display: block;
    }
  }

  .jlDialogPanel {
    .el-dialog__body {
      padding: 30px 0px;
    }
  }

  .el-upload-dragger {
    width: 64px;
    height: 64px;
  }

  .el-dropdown-link {
    cursor: pointer;
  }

  .imgQuestionCla {
    margin-left: 30px;
    overflow: auto;
    .el-upload--picture-card,
    .el-upload-list__item {
      width: 64px;
      height: 64px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    i {
      font-size: 13px;
    }

    div {
      display: flex;
    }

    ul {
      display: flex;
    }

    .el-icon-zoom-in {
      display: none;
    }

    .el-upload-list__item-delete {
      margin-left: 0px;
    }

    .imgCircleDiv {
      width: 64px;
      justify-content: center;
      margin-right: 8px;
    }

    .imgCircleCla {
      font-size: 16px;
    }

    .imgSquareCla {
      font-size: 16px;
    }
  }
}
.avatar-uploader-mic {
  margin-top: -2px;
  .el-upload .el-icon-mic {
    font-size: 15px;
    margin-left: 7px;
  }
}
</style>
